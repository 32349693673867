<template>
    <header class="page-title">
        <h1><i class="bi bi-list-ul"></i> 案件分類マスタ</h1>
    </header>

    <section class="section">
        <ul class="nav nav-tabs px-3">
            <li class="nav-item">
                <button type="button" @click="switchTab(1)" class="nav-link" :class="{'active': taxonomy === 1}">案件分類1</button>
            </li>
            <li class="nav-item">
                <button type="button" @click="switchTab(2)" class="nav-link" :class="{'active': taxonomy === 2}">案件分類2</button>
            </li>
            <li class="nav-item">
                <button type="button" @click="switchTab(3)" class="nav-link" :class="{'active': taxonomy === 3}">案件分類3</button>
            </li>
            <li class="nav-item">
                <button type="button" @click="switchTab(4)" class="nav-link" :class="{'active': taxonomy === 4}">案件分類4</button>
            </li>
        </ul>

        <div class="col-sm-36 col-md-18 col-lg-12">
            <table class="table">
                <thead>
                    <tr class="table-dark">
                        <th class="text-center col-4"></th>
                        <th class="text-center col-24">分類名</th>
                        <th class="text-center col-8"></th>
                    </tr>
                </thead>
                <template v-if="loading">
                    <tbody>
                        <tr v-for="i in Array(3)" :key="i">
                            <td class="align-middle text-center handle"><i class="bi bi-chevron-bar-expand"></i></td>
                            <td class="align-middle"><input type="text" class="form-control" disabled></td>
                            <td class="text-nowrap text-center">
                                <button type="submit" class="btn btn-info me-2" disabled>保存</button>
                                <button type="button" class="btn btn-outline-danger ms-2" disabled>削除</button>
                            </td>
                        </tr>
                    </tbody>
                </template>
                <template v-else-if="categories.length">
                    <draggable
                            v-model="categories"
                            item-key="category_id"
                            tag="tbody"
                            handle=".handle"
                            @end="sort()"
                    >
                        <template #item="{element}">
                            <tr>
                                <td class="align-middle text-center handle"><i class="bi bi-chevron-bar-expand"></i></td>
                                <td class="align-middle"><form-input required v-model="element.category_name" /></td>
                                <td class="text-nowrap text-center">
                                    <button type="submit" class="btn btn-info me-2" @click="update(element)">保存</button>
                                    <button type="button" class="btn btn-outline-danger ms-2" @click="removeReady(element)">削除</button>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                </template>
                <tfoot>
                    <tr>
                        <td></td>
                        <td class="align-middle"><form-input required v-model="category_to_create.category_name" :disabled="loading" /></td>
                        <td class="text-center">
                            <button class="btn btn-info" @click="create" :disabled="loading">追加</button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </section>

    <confirm-dialog ref="confirmRemove" @ok="remove">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import draggable from 'vuedraggable';
import Category from '@/models/entities/category';
import FormInput from '@/components/forms/FormInput';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';

export default {
    name: 'MasterCategory',
    components: {
        draggable,
        FormInput,
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            //ローディング
            loading: false,

            //表示中分類区分
            taxonomy: 1,
            //表示データ
            categories: [],

            //新規登録事業部
            category_to_create: new Category(),
            //削除対象事業部
            category_to_remove: null,
        }
    },
    mounted() {
        this.search();
    },
    methods: {
        //タブ切り替え
        switchTab(taxonomy) {
            this.taxonomy = taxonomy;
            this.search();
        },
        //検索
        search() {
            //表示データ初期化
            this.categories.splice(0);
            //ローディング開始
            this.loading = true;
            this.$http.get(`/master/taxonomy/${this.taxonomy}/category`)
            .then(response => {
                this.categories.splice(0);
                for (let row of response.data) {
                    this.categories.push(new Category(row));
                }
            })
            .finally(() => {
                //ローディング終了
                this.loading = false;
            });
        },
        //新規登録
        create() {
            if (this.$helper.isBlank(this.category_to_create.category_name)) {
                this.showErrorMessage('事業部名を入力してください');
                return;
            }

            this.startScreenLoading();

            this.category_to_create.taxonomy = this.taxonomy;

            this.$http.post(`/master/taxonomy/${this.taxonomy}/category`, this.category_to_create)
            .then(response => {
                // 登録されたデータをリストに追加
                this.categories.push(new Category(response.data));
                // 追加行を初期化
                this.category_to_create = new Category();

                this.showMessage('追加しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //保存
        update(category) {
            if (this.$helper.isBlank(category.category_name)) {
                this.showErrorMessage('事業部名を入力してください');
                return;
            }

            this.startScreenLoading();

            this.$http.put(`/master/taxonomy/${this.taxonomy}/category/${category.category_id}`, category)
            .then(() => {
                this.showMessage('保存しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //削除確認
        removeReady(category) {
            this.category_to_remove = category;
            this.$refs.confirmRemove.show();
        },
        //削除
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/master/taxonomy/${this.taxonomy}/category/${this.category_to_remove.category_id}`)
            .then(() => {
                // リストから削除
                let removed_index = this.categories.findIndex((row) => row.category_id === this.category_to_remove.category_id);
                this.categories.splice(removed_index, 1);
                // 値をリセット
                this.category_to_remove = null;

                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //ソート
        sort() {
            const category_ids = this.categories.map(category => category.category_id);

            this.$http.post(`/master/taxonomy/${this.taxonomy}/category/sort`, {
                category_ids: category_ids,
            });
        },
    }
}
</script>

<style scoped>

</style>
